<script setup lang="ts">

</script>

<template>
  <div class="min-h-screen w-full">
    <div class="relative h-full">
      <!-- Page Content -->
      <HeaderNavModern />
      <main class="min-h-[calc(100vh-var(--header-height))]">
        <NuxtPage />
      </main>
    </div>
  </div>
</template>

<style scoped>

</style>
